<script lang="ts" setup>
import type { LocationAsRelativeRaw } from 'vue-router'
import { getStructuredDataScript } from '~/utils/structured-data'

export interface BreadcrumbsItem {
    label: string
    url?: string
    route?: LocationAsRelativeRaw
}

interface BreadcrumbsProps {
    items: BreadcrumbsItem[]
}

const props = defineProps<BreadcrumbsProps>()

const list = ref<HTMLElement | null>()
const root = ref<HTMLElement | null>()
const isOverflow = ref(false)

watch(list, (el) => {
    isOverflow.value = !!el && el.offsetWidth > root.value!.offsetWidth
})

const router = useRouter()
const { $joinSiteUrl } = useNuxtApp()

const structuredDataScript = getStructuredDataScript({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    numberOfItems: props.items.length,
    itemListElement: props.items.map((item, index) => {
        const id = `page-${item?.url || item?.route?.name?.toString() || item.label}`
        const relativePath = item.url || (item.route && router.resolve(item.route)?.href)

        return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': id,
                name: item?.label || item?.route?.name,
                url: relativePath ? $joinSiteUrl(relativePath) : undefined,
            },
        }
    }),
})
</script>

<template>
    <nav
        v-if="items?.length"
        ref="root"
        aria-label="breadcrumbs"
        :class="[$style.root, isOverflow && $style['root--overflowing']]"
    >
        <component :is="structuredDataScript" />
        <ol ref="list" :class="[$style.list, isOverflow && $style['list--overflowing']]">
            <li v-for="(item, index) in items" :key="index" :class="$style.item">
                <VMenuLink :url="item.url" :route="item.route" :label="item.label" :class="$style.link" />
                <SvgIcon
                    v-if="index < items.length - 1"
                    :class="$style.chevron"
                    name="chevron-right"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                />
            </li>
        </ol>
    </nav>
</template>

<style lang="scss" module>
.root {
    width: 100%;

    @include media('<md') {
        display: none;
    }

    &--overflowing {
        overflow: hidden;
    }
}

.list {
    --v-menu-link-underline-display: none;
    --v-menu-link-inert-opacity: 1;

    display: flex;
    min-width: fit-content;
    flex-direction: var(--v-breadcrumbs-flex-direction, row);
    align-items: center;
    justify-content: center;
    gap: 1rem 0;

    @include media('>=md') {
        justify-content: initial;
    }
}

.item {
    display: inline-flex;
    align-items: center;
}

.link {
    display: block;
    overflow: hidden;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: rem(12 * 0.03);
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    .root--overflowing & {
        max-width: rem(100);
    }
}

.chevron {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin: 0 4px;
}
</style>
