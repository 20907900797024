<script setup lang="ts">
import { getStructuredDataScript } from '~/utils/structured-data'

setWindowWidthState()

const runtimeConfig = useRuntimeConfig()

const structuredDataScript = getStructuredDataScript({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: runtimeConfig.public.siteName,
    url: runtimeConfig.public.baseURL,
})
</script>

<template>
    <component :is="structuredDataScript" />

    <NuxtPage />
</template>
