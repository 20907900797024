<script lang="ts" setup>
import type { ContentBlock } from '~/types/api'

interface HeroHeaderContentBlockProps {
    contentBlock: ContentBlock
}

const props = defineProps<HeroHeaderContentBlockProps>()

const imgPath = computed(() => props.contentBlock.image?.relativePath || '')

const $img = useImage()

const defaultImgSrcset = computed(() => {
    const imgSizes = $img.getSizes(imgPath.value, {
        modifiers: {
            crop: '375x396',
            quality: '80',
            format: 'webp',
            align: 'center',
            densities: 'x1 x2',
        },
        provider: 'interventionRequest',
        sizes: '250px xs:100vw sm:100md md:100vw',
    })

    return imgSizes.srcset
})

const overMdImgSrcset = computed(() => {
    const imgSizes = $img.getSizes(imgPath.value, {
        modifiers: {
            quality: '80',
            crop: '1440x620',
            align: 'center',
            format: 'webp',
        },
        densities: 'x1 x2',
        provider: 'interventionRequest',
        sizes: 'lg:100vw vl:100vw xl:100vw xxl:100vw hd:100vw',
    })

    return imgSizes.srcset
})
</script>

<template>
    <VHeroHeader :title="contentBlock.title">
        <template #image>
            <picture v-if="imgPath" :class="$style.picture">
                <source media="(max-width: 767px)" :srcset="defaultImgSrcset" />
                <source :srcset="overMdImgSrcset" />
                <NuxtImg
                    provider="interventionRequest"
                    :alt="contentBlock.image?.title || contentBlock.title"
                    :src="imgPath"
                    width="1440"
                    height="620"
                    loading="eager"
                />
            </picture>
        </template>
    </VHeroHeader>
</template>

<style lang="scss" module="">
.picture {
    min-height: rem(396);

    img {
        width: 100%;
        height: auto;
        min-height: var(--v-header-image-min-height, rem(394));
        object-fit: cover;
    }
}
</style>
