const debug = (...args: any) => {
    const { $config } = useNuxtApp()
    if ($config.public?.apiDebug) {
        if (process.server) {
            console.log('\x1B[33m%s\x1B[0m', `[${new Date().toISOString()}]`, ...args)
            return
        }
        console.debug(...args)
    }
}
export default defineNuxtPlugin(() => {
    return {
        provide: {
            debug,
        },
    }
})
