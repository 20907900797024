<script setup lang="ts">
import type { LocationAsRelativeRaw } from 'vue-router'
import type { TranslateResult } from 'vue-i18n'
import VLoader from '~/components/atoms/VLoader/VLoader.vue'
import { NuxtLink } from '#components'
import type { Theme } from '~/types/app'

export type VButtonSize = 's' | 'm' | 'l' | 'xl'
export type Variant = 'menu' | 'anchor'

export interface VButtonProps {
    filled?: boolean
    loading?: boolean
    label?: string | false | TranslateResult
    size?: VButtonSize | false
    elevated?: boolean
    rounded?: boolean
    outlined?: boolean
    tertiary?: boolean
    disabled?: boolean
    tag?: string
    href?: string // external (absolute) or internal (relative) link
    to?: string | LocationAsRelativeRaw // internal link (use NuxtLink)
    iconLast?: boolean
    skeleton?: boolean
    variant?: Variant
    theme?: Theme | false
}

defineSlots<{
    default: (props: unknown) => unknown
    icon: (props: unknown) => unknown
}>()

const props = withDefaults(defineProps<VButtonProps>(), {
    iconLast: true,
    rounded: true,
    tertiary: false,
    outlined: false,
    filled: false,
    elevated: false,
    disabled: false,
    loading: false,
})

const rootTag = computed(() => {
    if (typeof props.tag === 'string') return props.tag

    if (props.to) return NuxtLink
    else if (props.href) return 'a'
    else return 'button'
})

const linkAttributes = computed(() => {
    const result: Record<string, unknown> = {}

    if (props.href) {
        result.href = props.href
    } else if (props.to) result.to = props.to

    if (rootTag.value === 'button' && props.disabled) result.disabled = true

    return result
})

const slots = useSlots()
const hasIcon = computed(() => !!slots.icon)
const hasLabel = computed(() => !!slots.default || !!props.label)

const { themeClass } = useTheme({ props })
const $style = useCssModule()
const rootClasses = computed(() => {
    return [
        $style.root,
        props.outlined && $style['root--outlined'],
        props.filled && $style['root--filled'],
        props.elevated && $style['root--elevated'],
        props.tertiary && $style['root--tertiary'],
        props.disabled && $style['root--disabled'],
        props.loading && $style['root--loading'],
        props.skeleton && $style['root--skeleton'],
        props.rounded && $style['root--rounded'],
        (props.iconLast || props.loading) && $style['root--icon-last'],
        (hasIcon.value || props.loading) && $style['root--has-icon'],
        hasLabel.value && $style['root--has-label'],
        props.size && $style['root--size-' + props.size],
        props.variant && $style['root--variant-' + props.variant],
        themeClass.value && themeClass.value,
    ]
})
</script>

<template>
    <component :is="rootTag" :class="rootClasses" v-bind="linkAttributes">
        <slot v-if="hasIcon && !loading" ref="icon" name="icon" data-icon />
        <VLoader v-if="loading" ref="icon" :class="$style.icon" />

        <span v-if="hasLabel" :class="$style.label">
            <slot>{{ label }}</slot>
        </span>
    </component>
</template>

<style lang="scss" module>
.root {
    @include v-button-default-css-vars($v-button);
    @include theme-variants;

    position: var(--v-button-position, relative);
    display: var(--v-button-display, inline-flex);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border: none;
    backface-visibility: hidden;
    color: var(--v-button-color, var(--theme-foreground-color));
    font-style: var(--v-button-font-style);
    text-align: left;
    text-transform: var(--v-button-text-transform, none);
    transition:
        background-color 0.3s,
        scale 0.45s,
        border-color 0.3s,
        color 0.3s;

    @media (hover: hover) {
        &:active {
            scale: 0.982;
        }
    }

    &--rounded {
        @include v-button-default-css-vars($v-button-rounded, 'rounded');
    }

    &--outlined {
        border-width: var(--v-button-border-width, 1px);
        border-style: solid;
        border-color: var(--v-button-border-color, var(--theme-foreground-color, currentColor));
    }

    &--filled {
        background-color: var(--v-button-filled-background-color, var(--theme-background-color));
    }

    &--outlined,
    &--filled {
        @include v-button-default-css-vars($v-button-filled, 'filled');
    }

    &--tertiary {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
    }

    &--tertiary:not(#{&}--filled) {
        color: var(--color-label-tertiary);
    }

    &--tertiary#{&}--has-icon:not(#{&}--has-label) {
        --v-button-min-width: none;

        padding: 0;
    }

    &:not(#{&}--outlined):not(#{&}--filled),
    &:not(#{&}--has-label) {
        padding: 0;
    }

    &--elevated {
        box-shadow: 0 2px 32px 0 rgba(#000, 0.1);
    }

    &--skeleton {
        // @include loading-animation;
        background-color: var(--theme-skeleton-background);
        color: transparent;
        pointer-events: none; // prevents click on disabled link (<a> or <nuxt-link>)
    }

    // DISABLED
    &--disabled {
        color: color(grey-400);
        cursor: not-allowed;
        pointer-events: none; // prevents click on disabled link (<a> or <nuxt-link>)
    }

    &--outlined#{&}--disabled {
        background-color: transparent;
    }

    &--filled#{&}--disabled {
        background: var(--theme-v-button-disabled-background, color(grey-500));
        color: var(--theme-v-button-disabled-foreground, color(grey-500));
        opacity: 0.5;
    }

    // LOADING
    &--loading {
        cursor: not-allowed;
        pointer-events: none; // prevents click on disabled link (<a> or <nuxt-link>)

        .icon {
            width: 1em;
            height: 1em;
            color: var(--v-button-color, var(--theme-foreground-color));

            .root--icon-last & {
                order: 2;
            }

            .root--skeleton & {
                visibility: hidden;
            }
        }
    }

    // HOVER
    @media (hover: hover) {
        &:where(#{&}--outlined, #{&}--filled):not(#{&}--disabled):where(:focus-visible, :hover) {
            background-color: var(--theme-v-button-hover-background);
            color: var(--theme-v-button-hover-foreground);
        }
    }

    // VARIANTS
    &--variant-menu {
        @include v-button-css-vars-by-size($v-button-menu-rounded, 's', 'rounded');
        @include v-button-default-css-vars($v-button-menu);

        @each $key, $value in $v-button-menu {
            &--size-#{$key} {
                @include v-button-size($key, menu);
            }
        }
    }

    &--variant-anchor {
        @include v-button-default-css-vars($v-button-anchor);

        @each $key, $value in $v-button-anchor {
            &--size-#{$key} {
                @include v-button-size($key, anchor);
            }
        }
    }

    // AUTOMATIC RESPONSIVE SIZE
    // Automatic apply css var on breakpoint
    //&--variant-unset#{&}--size-unset {
    //    @include media('>=lg') {
    //        @include all-variant-button-css-vars-by-size(default, m);
    //    }
    //}
    //

    // On props size set
    @each $key, $value in $v-button {
        &--size-#{$key} {
            @include v-button-size($key);
        }
    }
}

// can't apply class to icon slot directly
// be aware than all nested svg are styled
svg,
.icon {
    @include v-button-default-css-vars($v-button-icon, 'icon');

    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--v-button-icon-color, currentColor);
    line-height: 0;

    .root--icon-last & {
        order: 2;
    }

    .root--skeleton & {
        visibility: hidden;
    }

    .root--tertiary:not(.root--has-label) & {
        padding: 0;
        margin: 0;
    }
}

.label {
    @include v-button-default-css-vars($v-button-label, 'label');

    position: relative;
    z-index: 1;
    display: var(--v-button-label-display);
    padding-block: rem(3);
    text-overflow: ellipsis;
    white-space: nowrap;

    .root--skeleton & {
        visibility: hidden;
    }

    .root--tertiary &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--theme-foreground-color);
        content: '';
        opacity: 0;
        transition: opacity 0.3s;
    }

    @media (hover: hover) {
        .root--tertiary:not(#{&}--disabled):where(:focus-visible, :hover) &::after {
            opacity: 1;
            transition: none;
        }
    }
}
</style>
