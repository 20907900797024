export const throttle = (wait: number = 300, fn: Function) => {
    let inThrottle: boolean, lastFn: ReturnType<typeof setTimeout>, lastTime: number
    return function (this: unknown) {
        const context = this
        const args = arguments

        if (!inThrottle) {
            fn.apply(context, args)
            lastTime = Date.now()
            inThrottle = true
        } else {
            clearTimeout(lastFn)
            lastFn = setTimeout(
                () => {
                    if (Date.now() - lastTime >= wait) {
                        fn.apply(context, args)
                        lastTime = Date.now()
                    }
                },
                Math.max(wait - (Date.now() - lastTime), 0),
            )
        }
    }
}
