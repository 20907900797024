import { default as icons_45pageKFdQ6TLQOhMeta } from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.13.2/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91slug_93WWlCJ8FwTgMeta } from "/builds/mingat/mingat.com/frontend/pages/[slug].vue?macro=true";
import { default as indexMduJWp4f4zMeta } from "/builds/mingat/mingat.com/frontend/pages/accessoires/index.vue?macro=true";
import { default as _91slug_93pQeKuly7AKMeta } from "/builds/mingat/mingat.com/frontend/pages/agences/[slug].vue?macro=true";
import { default as indexHH8qq6R5gYMeta } from "/builds/mingat/mingat.com/frontend/pages/agences/index.vue?macro=true";
import { default as indexWa4dtgTD6QMeta } from "/builds/mingat/mingat.com/frontend/pages/contact/index.vue?macro=true";
import { default as indexEcb521lRVnMeta } from "/builds/mingat/mingat.com/frontend/pages/espace-professionnels/index.vue?macro=true";
import { default as index7sQVqLjcsJMeta } from "/builds/mingat/mingat.com/frontend/pages/index.vue?macro=true";
import { default as loginTXAK2m47e3Meta } from "/builds/mingat/mingat.com/frontend/pages/login.vue?macro=true";
import { default as _91id_93wMOxHgm6E1Meta } from "/builds/mingat/mingat.com/frontend/pages/mon-compte/reservations/[id].vue?macro=true";
import { default as index8uoCiYYUvMMeta } from "/builds/mingat/mingat.com/frontend/pages/mon-compte/reservations/index.vue?macro=true";
import { default as indexilHI2tx6iaMeta } from "/builds/mingat/mingat.com/frontend/pages/partenaires/index.vue?macro=true";
import { default as _91slug_939EhUAe9hCZMeta } from "/builds/mingat/mingat.com/frontend/pages/recrutement/[slug].vue?macro=true";
import { default as indexyeea4rVKEOMeta } from "/builds/mingat/mingat.com/frontend/pages/recrutement/index.vue?macro=true";
import { default as accessoiresoqcK6f4JIFMeta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/accessoires.vue?macro=true";
import { default as demandeuFF9YRtco7Meta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/demande.vue?macro=true";
import { default as indexDSslNM4L1tMeta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/index.vue?macro=true";
import { default as recapitulatify7ICABQ0qYMeta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/recapitulatif.vue?macro=true";
import { default as indexYRX1lVyJWgMeta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]/index.vue?macro=true";
import { default as confirmationifZSU4wPLsMeta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[id]/confirmation.vue?macro=true";
import { default as erreurQ5N2IF3jowMeta } from "/builds/mingat/mingat.com/frontend/pages/reservation/[id]/erreur.vue?macro=true";
import { default as nuxt_643_4610_463__64unocss_43reset_640_4658_469_eslint_648_4656_460_floating_45vue_645_462_462_rollup_644_4613_462_sass_641_4670SFz81F7NyIMeta } from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: icons_45pageKFdQ6TLQOhMeta?.name ?? "icons-page",
    path: icons_45pageKFdQ6TLQOhMeta?.path ?? "/_icons",
    meta: { ...(icons_45pageKFdQ6TLQOhMeta || {}), ...{"layout":"svg-sprite"} },
    alias: icons_45pageKFdQ6TLQOhMeta?.alias || [],
    redirect: icons_45pageKFdQ6TLQOhMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_rollup@4.13.2/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WWlCJ8FwTgMeta?.name ?? "slug___fr",
    path: _91slug_93WWlCJ8FwTgMeta?.path ?? "/:slug()",
    meta: _91slug_93WWlCJ8FwTgMeta || {},
    alias: _91slug_93WWlCJ8FwTgMeta?.alias || [],
    redirect: _91slug_93WWlCJ8FwTgMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMduJWp4f4zMeta?.name ?? "accessoires___fr",
    path: indexMduJWp4f4zMeta?.path ?? "/accessoires",
    meta: indexMduJWp4f4zMeta || {},
    alias: indexMduJWp4f4zMeta?.alias || [],
    redirect: indexMduJWp4f4zMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/accessoires/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pQeKuly7AKMeta?.name ?? "agences-slug___fr",
    path: _91slug_93pQeKuly7AKMeta?.path ?? "/agences/:slug()",
    meta: _91slug_93pQeKuly7AKMeta || {},
    alias: _91slug_93pQeKuly7AKMeta?.alias || [],
    redirect: _91slug_93pQeKuly7AKMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/agences/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHH8qq6R5gYMeta?.name ?? "agences___fr",
    path: indexHH8qq6R5gYMeta?.path ?? "/agences",
    meta: indexHH8qq6R5gYMeta || {},
    alias: indexHH8qq6R5gYMeta?.alias || [],
    redirect: indexHH8qq6R5gYMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/agences/index.vue").then(m => m.default || m)
  },
  {
    name: indexWa4dtgTD6QMeta?.name ?? "contact___fr",
    path: indexWa4dtgTD6QMeta?.path ?? "/contact",
    meta: indexWa4dtgTD6QMeta || {},
    alias: indexWa4dtgTD6QMeta?.alias || [],
    redirect: indexWa4dtgTD6QMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexEcb521lRVnMeta?.name ?? "espace-professionnels___fr",
    path: indexEcb521lRVnMeta?.path ?? "/espace-professionnels",
    meta: indexEcb521lRVnMeta || {},
    alias: indexEcb521lRVnMeta?.alias || [],
    redirect: indexEcb521lRVnMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/espace-professionnels/index.vue").then(m => m.default || m)
  },
  {
    name: index7sQVqLjcsJMeta?.name ?? "index___fr",
    path: index7sQVqLjcsJMeta?.path ?? "/",
    meta: index7sQVqLjcsJMeta || {},
    alias: index7sQVqLjcsJMeta?.alias || [],
    redirect: index7sQVqLjcsJMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginTXAK2m47e3Meta?.name ?? "login___fr",
    path: loginTXAK2m47e3Meta?.path ?? "/login",
    meta: loginTXAK2m47e3Meta || {},
    alias: loginTXAK2m47e3Meta?.alias || [],
    redirect: loginTXAK2m47e3Meta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wMOxHgm6E1Meta?.name ?? "mon-compte-reservations-id___fr",
    path: _91id_93wMOxHgm6E1Meta?.path ?? "/mon-compte/reservations/:id()",
    meta: _91id_93wMOxHgm6E1Meta || {},
    alias: _91id_93wMOxHgm6E1Meta?.alias || [],
    redirect: _91id_93wMOxHgm6E1Meta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/mon-compte/reservations/[id].vue").then(m => m.default || m)
  },
  {
    name: index8uoCiYYUvMMeta?.name ?? "mon-compte-reservations___fr",
    path: index8uoCiYYUvMMeta?.path ?? "/mon-compte/reservations",
    meta: index8uoCiYYUvMMeta || {},
    alias: index8uoCiYYUvMMeta?.alias || [],
    redirect: index8uoCiYYUvMMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/mon-compte/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: indexilHI2tx6iaMeta?.name ?? "partenaires___fr",
    path: indexilHI2tx6iaMeta?.path ?? "/partenaires",
    meta: indexilHI2tx6iaMeta || {},
    alias: indexilHI2tx6iaMeta?.alias || [],
    redirect: indexilHI2tx6iaMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/partenaires/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939EhUAe9hCZMeta?.name ?? "recrutement-slug___fr",
    path: _91slug_939EhUAe9hCZMeta?.path ?? "/recrutement/:slug()",
    meta: _91slug_939EhUAe9hCZMeta || {},
    alias: _91slug_939EhUAe9hCZMeta?.alias || [],
    redirect: _91slug_939EhUAe9hCZMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/recrutement/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexyeea4rVKEOMeta?.name ?? "recrutement___fr",
    path: indexyeea4rVKEOMeta?.path ?? "/recrutement",
    meta: indexyeea4rVKEOMeta || {},
    alias: indexyeea4rVKEOMeta?.alias || [],
    redirect: indexyeea4rVKEOMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/recrutement/index.vue").then(m => m.default || m)
  },
  {
    name: accessoiresoqcK6f4JIFMeta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle-accessoires___fr",
    path: accessoiresoqcK6f4JIFMeta?.path ?? "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()/accessoires",
    meta: accessoiresoqcK6f4JIFMeta || {},
    alias: accessoiresoqcK6f4JIFMeta?.alias || [],
    redirect: accessoiresoqcK6f4JIFMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/accessoires.vue").then(m => m.default || m)
  },
  {
    name: demandeuFF9YRtco7Meta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle-demande___fr",
    path: demandeuFF9YRtco7Meta?.path ?? "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()/demande",
    meta: demandeuFF9YRtco7Meta || {},
    alias: demandeuFF9YRtco7Meta?.alias || [],
    redirect: demandeuFF9YRtco7Meta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/demande.vue").then(m => m.default || m)
  },
  {
    name: indexDSslNM4L1tMeta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle___fr",
    path: indexDSslNM4L1tMeta?.path ?? "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()",
    meta: indexDSslNM4L1tMeta || {},
    alias: indexDSslNM4L1tMeta?.alias || [],
    redirect: indexDSslNM4L1tMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/index.vue").then(m => m.default || m)
  },
  {
    name: recapitulatify7ICABQ0qYMeta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle-recapitulatif___fr",
    path: recapitulatify7ICABQ0qYMeta?.path ?? "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()/recapitulatif",
    meta: recapitulatify7ICABQ0qYMeta || {},
    alias: recapitulatify7ICABQ0qYMeta?.alias || [],
    redirect: recapitulatify7ICABQ0qYMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/recapitulatif.vue").then(m => m.default || m)
  },
  {
    name: indexYRX1lVyJWgMeta?.name ?? "reservation-category-depAgency-depTime-retTime___fr",
    path: indexYRX1lVyJWgMeta?.path ?? "/reservation/:category()-:depAgency()-:depTime()-:retTime()",
    meta: indexYRX1lVyJWgMeta || {},
    alias: indexYRX1lVyJWgMeta?.alias || [],
    redirect: indexYRX1lVyJWgMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmationifZSU4wPLsMeta?.name ?? "reservation-id-confirmation___fr",
    path: confirmationifZSU4wPLsMeta?.path ?? "/reservation/:id()/confirmation",
    meta: confirmationifZSU4wPLsMeta || {},
    alias: confirmationifZSU4wPLsMeta?.alias || [],
    redirect: confirmationifZSU4wPLsMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[id]/confirmation.vue").then(m => m.default || m)
  },
  {
    name: erreurQ5N2IF3jowMeta?.name ?? "reservation-id-erreur___fr",
    path: erreurQ5N2IF3jowMeta?.path ?? "/reservation/:id()/erreur",
    meta: erreurQ5N2IF3jowMeta || {},
    alias: erreurQ5N2IF3jowMeta?.alias || [],
    redirect: erreurQ5N2IF3jowMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/pages/reservation/[id]/erreur.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64unocss_43reset_640_4658_469_eslint_648_4656_460_floating_45vue_645_462_462_rollup_644_4613_462_sass_641_4670SFz81F7NyIMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64unocss_43reset_640_4658_469_eslint_648_4656_460_floating_45vue_645_462_462_rollup_644_4613_462_sass_641_4670SFz81F7NyIMeta?.path ?? "/sitemap.xml",
    meta: nuxt_643_4610_463__64unocss_43reset_640_4658_469_eslint_648_4656_460_floating_45vue_645_462_462_rollup_644_4613_462_sass_641_4670SFz81F7NyIMeta || {},
    alias: nuxt_643_4610_463__64unocss_43reset_640_4658_469_eslint_648_4656_460_floating_45vue_645_462_462_rollup_644_4613_462_sass_641_4670SFz81F7NyIMeta?.alias || [],
    redirect: nuxt_643_4610_463__64unocss_43reset_640_4658_469_eslint_648_4656_460_floating_45vue_645_462_462_rollup_644_4613_462_sass_641_4670SFz81F7NyIMeta?.redirect,
    component: () => import("/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]