import { joinURL } from 'ufo'
import type { UseSeoMetaInput } from '@unhead/schema'
import type { ReachableEntity } from '~/types/app'

type PageEntity = ReachableEntity & { title?: string; description?: string }

interface UsePageSeoMetaOptions {
    title?: string
    description?: string
    noIndex?: boolean
}

function getImageUrl(path: string | null | undefined): string {
    if (path) {
        const img = useImage()

        return img(path, { width: 1200 }, { provider: 'interventionRequest' })
    } else {
        const runtimeConfig = useRuntimeConfig()

        return joinURL(runtimeConfig.public.baseURL, '/images/share.jpg')
    }
}

export function usePageSeoMeta(webResponse?: MaybeRef<PageEntity | null | undefined>, options?: UsePageSeoMetaOptions) {
    const runtimeConfig = useRuntimeConfig()
    const { $canonicalUrl } = useNuxtApp()

    const entity = toValue(webResponse)

    const webResponseTitle = entity?.seoTitle || options?.title || entity?.title
    const title = webResponseTitle
        ? `${webResponseTitle} - ${runtimeConfig.public.seo.title}`
        : runtimeConfig.public.seo.longTitle

    const description = entity?.seoDescription || options?.description || runtimeConfig.public.seo.description
    const imagePath = entity?.image?.relativePath

    const metaServer: UseSeoMetaInput & { canonical?: string } = {
        canonical: $canonicalUrl.value,
        // socials
        ogSiteName: runtimeConfig.public.siteName,
        ogUrl: $canonicalUrl.value,
        ogTitle: title,
        twitterTitle: title,
        ogDescription: description,
        twitterDescription: description,
        ogImage: getImageUrl(imagePath),
        twitterCard: 'summary',
    }

    const metaClient = {
        title,
        description,
        robots: options?.noIndex || entity?.noIndex ? 'noindex, nofollow' : 'index, follow',
    }

    return { metaServer, metaClient }
}
