<script setup lang="ts">
import type { ContentBlock } from '~/types/api'
import VDefaultContentBlock from '~/components/organisms/VContentBlock/VDefaultContentBlock.vue'
import VHeroHeaderContentBlock from '~/components/organisms/VContentBlock/VHeroHeaderContentBlock.vue'

interface ContentBlockFactoryProps {
    contentBlocks: Array<ContentBlock> | undefined
}

const props = defineProps<ContentBlockFactoryProps>()

defineOptions({
    inheritAttrs: false,
})

function getGroupedIndexList() {
    const groupedBlockIndexes: ([number] | [number, number])[] = []

    props.contentBlocks?.forEach((block, i, list) => {
        const isCard = block.type === 'card'
        const previousIsCard = list[i - 1]?.type === 'card'

        if (isCard && !previousIsCard) {
            groupedBlockIndexes.push([i])
        } else if (previousIsCard && !isCard) {
            groupedBlockIndexes.at(-1)?.push(i)
            groupedBlockIndexes.push([i])
        } else if (previousIsCard && isCard && i === list.length - 1) {
            groupedBlockIndexes.at(-1)?.push(i)
        } else if (!isCard && !previousIsCard) {
            groupedBlockIndexes.push([i])
        }
    })
    return groupedBlockIndexes
}

const groupedBlock = computed(() => {
    return getGroupedIndexList().map(([start, end]) => {
        const arrayLength = typeof end !== 'number' ? 1 : end - start

        const contentBlockList = props.contentBlocks?.slice().splice(start, arrayLength) || []
        return contentBlockList.filter((b) => b.id)
    })
})

const slideIndex = ref(0)
</script>

<template>
    <template v-for="(blocks, index) in groupedBlock" :key="index">
        <VCarousel
            v-if="blocks.length > 1"
            v-slot="{ slideClass }"
            v-model="slideIndex"
            wrapper-tag="section"
            :class="$style.carousel"
            async-slides
        >
            <VCarouselAsyncSlide v-for="cardBlock in blocks" :key="cardBlock.id" v-slot="{ setRef }">
                <VDefaultContentBlock
                    :ref="(el) => setRef(el)"
                    tag="div"
                    :content-block="cardBlock"
                    :class="[...slideClass, $style['carousel-item']]"
                />
            </VCarouselAsyncSlide>
        </VCarousel>
        <component
            :is="blocks[0].type === 'hero_header' ? VHeroHeaderContentBlock : VDefaultContentBlock"
            v-else
            :tag="blocks[0].type !== 'hero_header' ? 'section' : undefined"
            :content-block="blocks[0]"
            :class="[$style.block, $attrs.class, blocks[0].type === 'hero_header' && $style['block--layout-hero']]"
        />
    </template>
</template>

<style lang="scss" module>
.carousel {
    --v-carousel-max-width: #{flex-grid(7, 22)};
    --v-carousel-align-item: start;

    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    gap: rem(42) var(--gutter);
    margin-block: rem(32);

    @include media('<vl') {
        --v-carousel-root-width: calc(100% + var(--gutter) * 2);

        margin-left: calc(var(--gutter) * -1);
        padding-inline: var(--gutter);
    }

    @include media('>=md') {
        margin-block: rem(48) rem(64);
    }
}

.carousel-item {
    --v-carousel-slide-width: #{flex-grid(7, 22)};
    --v-carousel-slide-margin-right: calc(var(--gutter) + #{flex-grid-value(1, 22)});

    min-width: rem(307);
    max-width: rem(447);
}

.block {
    margin-block: rem(24);

    @include media('>=vl') {
        margin-block: rem(48);
    }

    &--layout-hero {
        max-width: inherit;
        margin-block: inherit;
    }
}
</style>
