export type VCarouselRegisteredElement = HTMLElement | null

export interface CarouselInject {
    registerSlide: (el?: VCarouselRegisteredElement) => void
}

interface UseCarouselOptions {
    element?: Ref<HTMLElement | null>
    asyncSlides?: boolean
    lazy?: boolean
    init?: () => void
    observerOptions?: IntersectionObserverInit | undefined
}

export function useCarousel(options?: UseCarouselOptions) {
    const { lazy, observerOptions, element, asyncSlides, init } = options || {}
    const initialized = ref(false)

    // AsyncSlide
    const registeredSlides = ref<VCarouselRegisteredElement[]>([])
    const numRegisteredSlides = computed(() => registeredSlides.value.length)

    const isSlidesReady = computed(() => {
        const carouselChildren = element?.value?.children.length

        if (!carouselChildren || numRegisteredSlides.value === 0) return false

        return numRegisteredSlides.value >= carouselChildren
    })

    watch(isSlidesReady, () => {
        if (!initialized.value) initCarousel()
    })

    const registerSlide = (el: VCarouselRegisteredElement) => {
        if (el) registeredSlides.value.push(el)
    }

    // Lazy: IntersectionObserver
    let observer: IntersectionObserver | null = null
    const isVisible = ref(false)

    watch(isVisible, (value) => {
        if (!value) return

        disposeObserver()
        if (!initialized.value) initCarousel()
    })

    const createObserver = () => {
        if (!element?.value) return

        observer = new IntersectionObserver(
            ([entry]: IntersectionObserverEntry[]) => {
                isVisible.value = entry.isIntersecting
            },
            {
                rootMargin: '200px 0px',
                ...observerOptions,
            },
        )
        observer.observe(element.value)
    }

    const disposeObserver = () => {
        observer?.disconnect()
        observer = null
    }

    // Commons
    const initCarousel = () => {
        initialized.value = true
        init?.()
    }

    if (asyncSlides) provide('registerSlide', registerSlide)

    onMounted(() => {
        if (lazy) {
            createObserver()
        } else if (!asyncSlides) {
            initCarousel()
        }
    })

    onBeforeUnmount(() => {
        disposeObserver()
    })

    return { numSlides: numRegisteredSlides, initialized, observer, isVisible, slideElements: registeredSlides }
}
