import { FetchError } from 'ofetch'
import type { ContentBlock, JsonLdCollection } from '~/types/api'

export default defineNuxtRouteMiddleware(async () => {
    // Only run on server side
    if (!process.server) return
    const { $apiFetch } = useNuxtApp()
    const contentBlocksState = useContentBlocksState()
    if (!contentBlocksState.value) {
        try {
            contentBlocksState.value = (
                await $apiFetch<JsonLdCollection<ContentBlock>>('/content_blocks', {
                    query: {
                        'exists[webPage]': false,
                        'order[position]': 'ASC',
                    },
                })
            )?.['hydra:member']
        } catch (e: unknown) {
            throw createError({
                statusCode: 503,
                message: (e as FetchError).message,
            })
        }
    }
})
