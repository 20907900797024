<script setup lang="ts">
import type { Swiper, SwiperModule, SwiperOptions } from 'swiper/types'
import type { ComponentPublicInstance } from 'vue'

export interface VCarouselProps {
    role?: string
    wrapperTag?: string
    innerTag?: string
    modelValue?: number
    swiperOptions?: SwiperOptions
    asyncSlides?: undefined | boolean // Init swiper from VCarouselAsyncSlide
    lazy?: undefined | boolean // init with IntersectionObserver
    observerOptions?: IntersectionObserverInit
    slideList?: (ComponentPublicInstance | HTMLElement)[] | undefined
}

const props = withDefaults(defineProps<VCarouselProps>(), { lazy: undefined, asyncSlides: undefined })

const emit = defineEmits<{
    'update:modelValue': [number]
    enable: []
    disable: []
    snapGridLengthChange: [number]
    progress: [number]
    transitioningSlide: [boolean]
    transitionEnd: [Swiper]
}>()

const root = ref<HTMLElement | null>(null)
const wrapper = ref<HTMLElement | null>(null)

const slots = defineSlots<{
    default: (slotProps: { slideClass: string[] }) => void
}>()

const { slideElements } = useCarousel({
    element: wrapper,
    lazy: props.lazy,
    asyncSlides: props.asyncSlides,
    observerOptions: props.observerOptions,
    init: initCarousel,
})

// SWIPER
let isLoadingSwiper: boolean = false
let swiper: Swiper | null = null
let resizeObserver: ResizeObserver | null = null

const isEnd = ref(false)

function isOverflowing() {
    const lastSlide = slideElements.value[slideElements.value.length - 1]

    if (!root.value || !lastSlide) return false

    const displayed = getComputedStyle(root.value).display !== 'none'

    const wrapperBound = root.value.getBoundingClientRect()
    const lastSlideRight = lastSlide.getBoundingClientRect().right - wrapperBound.left
    const isLastSlideOverflow = Math.floor(lastSlideRight) > Math.ceil(wrapperBound.width)

    return displayed && isLastSlideOverflow
}

function initCarousel() {
    if ((props.asyncSlides && isOverflowing()) || !props.asyncSlides) {
        createSwiper()
    } else if (props.asyncSlides && !isOverflowing()) {
        initResizeObserver()
    }
}

function initResizeObserver() {
    if (resizeObserver) return

    resizeObserver = new ResizeObserver(onResizeObserverChange)
    const target = swiper?.wrapperEl || wrapper.value

    target && resizeObserver?.observe(target)
}

function onResizeObserverChange() {
    if (props.asyncSlides && isOverflowing() && !swiper) createSwiper()
    swiper?.update()
}

function disposeSwiper() {
    resizeObserver?.disconnect()
    resizeObserver = null

    swiper?.destroy(true, false)
}

async function createSwiper() {
    if (!slots?.default || swiper || isLoadingSwiper || !root.value) return

    isLoadingSwiper = true

    const SwiperBundle = await import('swiper')
    const modules: SwiperModule[] = []
    const options = props.swiperOptions

    isLoadingSwiper = false

    swiper = new SwiperBundle.Swiper(root.value, {
        modules,
        grabCursor: true,
        preventInteractionOnTransition: false,
        threshold: 6,
        slidesPerView: 'auto',
        loopPreventsSliding: false,
        touchEventsTarget: 'container', // fire touchStart event also on slide margin
        watchOverflow: true,
        initialSlide: props.modelValue,
        ...props.swiperOptions,
        on: {
            ...props.swiperOptions?.on,
            snapIndexChange: (swiper: Swiper): void => {
                // with slidesPerView: 'auto' slideNext is disabled when last slide is fully enter in window
                emit('update:modelValue', options?.loop ? swiper.realIndex : swiper.snapIndex)

                isEnd.value = swiper.isEnd
            },
            init: (swiper: Swiper): void => {
                if (!resizeObserver) initResizeObserver()

                swiper && options?.on?.init?.(swiper)
                checkSwiperActivation(swiper)
            },
            update: (swiper: Swiper): void => {
                checkSwiperActivation(swiper)
            },
            progress: (_swiper: Swiper, progress: number) => {
                emit('progress', progress)
            },
            beforeTransitionStart() {
                emit('transitioningSlide', true)
            },
            transitionEnd(swiper: Swiper) {
                emit('transitioningSlide', false)
                emit('transitionEnd', swiper)
            },
            snapGridLengthChange: (swiper: Swiper) => {
                emit('snapGridLengthChange', swiper.snapGrid.length)
            },
        },
    })
}

function checkSwiperActivation(swiper: Swiper) {
    const swiperWrapper = root.value?.querySelector('.swiper-wrapper') || wrapper.value
    const isGrid = !!swiperWrapper && getComputedStyle(swiperWrapper).display === 'grid'

    const enable = (swiper.allowSlideNext || swiper.allowSlidePrev) && !isGrid

    if (enable) {
        swiper.enable()
        emit('enable')
    } else {
        swiper.slideTo(0)
        swiper.disable()
        emit('disable')
        if (root.value) root.value.style.cursor = 'initial'
    }
}

watch(
    () => props.modelValue,
    () => {
        if (typeof props.modelValue === 'undefined' || !swiper) return

        if (props.swiperOptions?.loop) swiper.slideToLoop(props.modelValue)
        else swiper.slideTo(props.modelValue)
    },
)

onBeforeUnmount(() => {
    disposeSwiper()
})

defineExpose({
    root,
})

// STYLE
const $style = useCssModule()
const rootClasses = computed(() => {
    return [$style.root]
})
</script>

<template>
    <component :is="wrapperTag || 'div'" ref="root" :class="rootClasses">
        <component :is="innerTag || 'div'" ref="wrapper" class="swiper-wrapper" :class="$style.wrapper">
            <slot :slide-class="[$style.slide, 'swiper-slide']" :slide-list="slideList" />
        </component>
    </component>
</template>

<style lang="scss" module>
.root {
    // display: flex;
    width: var(--v-carousel-root-width, 100%);
}

.wrapper {
    display: flex;
    min-width: var(--v-carousel-min-width, 100%);
    max-width: var(--v-carousel-max-width);
    height: var(--v-carousel-swiper-wrapper-height);
    flex-shrink: 0;
    align-items: var(--v-carousel-align-item);
    gap: var(--v-carousel-gap);
    touch-action: pan-y;

    // flex gap not working w/ swiper

    @media (prefers-reduced-motion) {
        transition: none !important;
    }
}

.slide {
    width: var(--v-carousel-slide-width, 100%);
    flex-shrink: 0;
    margin-right: var(--v-carousel-slide-margin-right);

    &:last-child {
        margin-right: var(--v-carousel-last-slide-margin-right, 0);
    }
}

.controls {
    margin-top: var(--v-carousel-controls-margin-top, rem(48));
    grid-column: 1 / -1;

    @include media('>=lg') {
        margin-top: rem(80);
    }
}
</style>
