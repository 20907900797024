import revive_payload_client_EfbKYyVKdq from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_C1yZPf2mTm from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_BRb5Sy3P1G from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_b8TznZCR54 from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt-site-config@2.2.9_@nuxt+devtools@1.1.5_@vue+compiler-core@3.4.21_nuxt@3.10.3_postcss@8.4_wgk4cwkn43qjt2mon2uciw2uca/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_i3i09Tk9Bn from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_sYNXvwSier from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/mingat/mingat.com/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_1WhGrfpTOp from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ouGjE8rOah from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.10.3_rollup@4.13.2_vue@3.4.21/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_fx3Jv4Fx5Q from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_rollup@4.13.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import i18n_JokArpVhpD from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.13.2_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_iXfqclk5Lj from "/builds/mingat/mingat.com/frontend/node_modules/.pnpm/nuxt@3.10.3_@unocss+reset@0.58.9_eslint@8.56.0_floating-vue@5.2.2_rollup@4.13.2_sass@1.70.0_s_huk743quoahgembbiw3fgm7c2i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_debug_CzQoA0myfc from "/builds/mingat/mingat.com/frontend/plugins/00.debug.ts";
import _01_sentry_client_2rh1pDAIty from "/builds/mingat/mingat.com/frontend/plugins/01.sentry.client.ts";
import _10_mingat_api_WlYmeaB8p8 from "/builds/mingat/mingat.com/frontend/plugins/10.mingat-api.ts";
import _11_url_resolver_4Yu8OnWGIl from "/builds/mingat/mingat.com/frontend/plugins/11.url-resolver.ts";
export default [
  revive_payload_client_EfbKYyVKdq,
  unhead_C1yZPf2mTm,
  router_BRb5Sy3P1G,
  _0_siteConfig_b8TznZCR54,
  payload_client_i3i09Tk9Bn,
  check_outdated_build_client_sYNXvwSier,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1WhGrfpTOp,
  plugin_ouGjE8rOah,
  plugin_client_fx3Jv4Fx5Q,
  i18n_JokArpVhpD,
  chunk_reload_client_iXfqclk5Lj,
  _00_debug_CzQoA0myfc,
  _01_sentry_client_2rh1pDAIty,
  _10_mingat_api_WlYmeaB8p8,
  _11_url_resolver_4Yu8OnWGIl
]