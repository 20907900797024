<script lang="ts" setup>
import type { ComponentPublicInstance } from 'vue'
import { getHtmlElement } from '~/utils/ref/get-html-element'

const slideItem = ref<HTMLElement | null>(null)

const setSlotRef = (el: HTMLElement | ComponentPublicInstance | undefined) => {
    if (!slideItem.value) {
        slideItem.value = getHtmlElement(el)
    }
}

useCarouseSlide(slideItem)
</script>

<template>
    <slot ref="slide" :set-ref="setSlotRef" />
</template>
