<script lang="ts" setup>
import type { File } from '~/types/api'
import type { BreadcrumbsItem } from '~/components/molecules/VBreadcrumbs/VBreadcrumbs.vue'

export interface HeroHeaderProps {
    title: string
    breadcrumbsItems?: BreadcrumbsItem[]
    image?: File | undefined | null
    imgSrc?: string
    alt?: string | undefined
}

const props = defineProps<HeroHeaderProps>()
const slot = useSlots()

const hasMedia = computed(() => !!props.image?.processable || !!slot.image || !!props.imgSrc)
const imageAlt = computed(() => props.alt || props.image?.title || props.title)

const pictureAttrs = computed(() => {
    if (props.image?.processable && props.image?.relativePath) {
        return {
            provider: 'interventionRequest',
            src: props.image.relativePath,
            modifiers: { crop: '1440x500', align: 'top' }, // image ratio define HeaderHeight
        }
    } else if (props.imgSrc) {
        return {
            src: props.imgSrc,
            provider: 'ipx',
        }
    }
})

const $style = useCssModule()
const rootClasses = computed(() => {
    return [$style.root, hasMedia.value ? $style['root--has-media'] : $style['root--no-media']]
})
</script>

<template>
    <div :class="rootClasses">
        <div v-if="hasMedia" :class="$style['media-wrapper']" class="grid-item-full-edge">
            <slot name="image">
                <NuxtPicture
                    v-bind="pictureAttrs"
                    :class="$style.media"
                    sizes="800px sm:100vw md:100vw lg:100vw vl:100vw xl:100vw xxl:100vw hd:100vw"
                    :alt="imageAlt"
                    width="1440"
                    height="500"
                    :img-attrs="{ loading: 'eager' }"
                />
            </slot>
        </div>
        <div class="container" :class="$style.content">
            <VBreadcrumbs v-if="breadcrumbsItems?.length" :class="$style.breadcrumbs" :items="breadcrumbsItems" />
            <h1 :class="$style.title" class="text-h1-sm cap-black-italic">
                {{ title }}
                <slot name="additional-title" />
            </h1>
            <slot />
        </div>
        <div v-if="hasMedia" :class="$style['logo-outline']">
            <SvgIcon name="logo-full-outline" :class="$style.logo" width="1550" height="295" viewBox="0 0 1550 295" />
        </div>
    </div>
</template>

<style lang="scss" module>
.root {
    position: relative;
    min-height: rem(396);
    max-height: var(--v-hero-header-max-height, rem(700));
    margin-top: -$v-top-bar-s !important;
    clip-path: inset(0 -100vmax);

    @include media('>=md') {
        min-height: rem(460);
    }

    &--no-media {
        min-height: inherit;
    }

    @include media('>=vl') {
        margin-top: -$v-top-bar-m !important;
    }
}

.media-wrapper {
    position: relative;
    overflow: auto hidden;

    &::before,
    &::after {
        position: absolute;
        content: '';
        inset: 0;
        pointer-events: none;
    }

    &::before {
        background: linear-gradient(200deg, rgb(1 1 1 / 0%) 20%, #010101 100%);
        opacity: 0.6;
    }

    &::after {
        background: rgb(1 1 1 / 5%);
    }
}

.media {
    img {
        width: 100%;
        height: auto;
        min-height: var(--v-header-image-min-height, rem(350));
        object-fit: cover;
    }
}

.content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: calc(var(--v-reservation-offset-top) * -1 + #{rem(54)});
    color: var(--color-white);
    inset: 0;

    .root--no-media & {
        position: relative;
        color: color(black);
        padding-block: max($v-top-bar-m + rem(96), 11vw) rem(48 + 24);
    }

    @include media('>=md') {
        align-items: initial;
    }
}

.title {
    z-index: 1;
    max-width: var(--v-hero-header-title-max-width, 15ch);
    margin-top: var(--spacing-2-xs, 16px);
    text-align: center;

    @include media('>=md') {
        @include text-h1;

        text-align: initial;
    }
}

.logo-outline {
    position: absolute;
    bottom: rem(20);
    overflow: hidden;
    width: 100%;
    max-height: 200px;
    color: var(--color-brand-yellow);
    opacity: 0.4;
    pointer-events: none;

    @include media('>=md') {
        bottom: 0;
    }
}

.logo {
    width: 100%;
    height: auto;
}
</style>
