<script setup lang="ts">
import type { LocationAsRelativeRaw } from 'vue-router'

interface VLinkProps {
    to?: LocationAsRelativeRaw | string
    href?: string
    custom?: boolean
}

const props = defineProps<VLinkProps>()

const { linkAttributes, url } = useLinkResolver({
    to: props.to,
    href: props.href,
})
</script>

<template>
    <slot v-if="custom || !url" :link-attrs="{ ...linkAttributes }" />
    <NuxtLink v-else v-bind="linkAttributes"><slot /></NuxtLink>
</template>
