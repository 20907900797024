import { isJwtExpired, isJwtRefreshable, parseJwt } from '~/utils/jwt'
import type { User } from '~/types/api'

export default defineNuxtRouteMiddleware(async () => {
    //
    // Only run on client side
    // server/middleware/11.refresh-token.get.ts is used for server side
    //
    if (process.server) return
    const session = useSessionCookie()
    const currentUser = useCurrentUserState()
    const { $apiFetch, $refreshToken } = useNuxtApp()
    if (session.value?.token && session.value?.refresh_token && session.value?.refresh_token_expiration) {
        const jwt = parseJwt(session.value.token)
        if (!jwt || !isJwtExpired(jwt)) {
            return
        }

        // if refresh_token expired empty session
        if (!isJwtRefreshable(jwt, session.value.refresh_token_expiration)) {
            console.log('Refresh token expired, empty session...')
            session.value = {}
            return
        }
        console.log('Need to refresh JWT...')
        try {
            // refresh
            await $refreshToken()
            currentUser.value = await $apiFetch<User>('/me')
        } catch (e) {
            // if refresh fails empty session before throwing
            session.value = undefined
            throw e
        }
    }
})
